import {
  takeEvery, call, put,
} from 'redux-saga/effects';
import api from '../../common/api';
import queryString from 'query-string';

import {
  apiSendAuth,
} from './api';

import {
  actionTypes,
  sendAuthRequest,
  sendAuthSuccess,
  sendAuthError,
} from './actions';

import { requestCodeText } from '../recovery/constants';

import onlyNumber from '../../common/onlyNumber';
import { setToken, redirectAuthorizedUser } from '../../common/auth';

const formattedData = (data) => ({
  ...data,
  login: onlyNumber(data.login),
});

const formattedError = (error) => {
  const { response: { data: { error: textError } } } = error;

  if (textError && requestCodeText[textError]) {
    return requestCodeText[textError];
  }

  if (textError) {
    return textError;
  }

  return error;
};

function* sendAuthSaga({ data }) {
  try {
    yield put(sendAuthRequest());
    const query = queryString.parse(window.location.search);
    const { token, expires_in: expiresIn, message } = yield call(() => apiSendAuth(formattedData(data)));

    if (token) {
      yield put(sendAuthSuccess());
      setToken(token, expiresIn);
      redirectAuthorizedUser({ query });
    } else {
      const text = requestCodeText[message] || 'Что-то пошло не так';
      yield put(sendAuthError(text));
    }
  } catch (error) {
    console.error(error);
    yield put(sendAuthError(formattedError(error)));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.SEND_AUTH, sendAuthSaga);
}
